import React from "react";
import Button from "./Button";

const AboutUsSection = () => {
    return (
        <div className="bg-gray-50 py-16">
            <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-3 items-center">
                {/* Left Section: Image (1:1 Aspect Ratio) */}
                <div className="flex text-center justify-center">
                    <div className="flex justify-center items-center bg-[#1e1e1ea8] rounded-full aspect-square w-64 md:w-72 relative">
                        <img
                            src="/assets/Aboutusimg.png"
                            alt="POS Illustration"
                            className="w-[220px] md:w-[300px] absolute bottom-[-20px]  md:bottom-[-40px]  "
                        />
                    </div>

                </div>



                {/* Right Section: Text (spanning 2 cols) */}
                <div className="md:col-span-2">
                    <div className="mb-4">
                        <Button text="About Us" />
                    </div>
                    <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900">
                        TRIOS International Computers <br />
                        & Requisites Trading LLC
                    </h2>
                    <div className="mt-6 space-y-2 text-gray-700">
                        <p>
                            <span className="font-bold">Established in 2019 –</span>{" "}
                            TRIOS International was founded in Dubai, UAE, with a mission to
                            integrate innovative ideas and technological advancements for a stronger
                            business environment.
                        </p>
                        <p>
                            <span className="font-bold">Commitment to International Standards –</span>{" "}
                            The company strives to align with global industry standards, ensuring
                            high quality and consistency.
                        </p>
                        <p>
                            <span className="font-bold">Focus on Technology –</span> With an
                            awareness of rapid technological changes, TRIOS's team remains
                            dedicated to staying at the forefront.
                        </p>
                        <p>
                            <span className="font-bold">Dedicated Professionals –</span> TRIOS's
                            skilled team is committed to delivering exceptional products and
                            services.
                        </p>
                        <p>
                            <span className="font-bold">After-Sales Support –</span> TRIOS
                            guarantees unparalleled customer support after every sale, valuing
                            customer satisfaction.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsSection;
