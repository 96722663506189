import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { IconSquare, IconDeviceLaptop, IconPrinter, IconBox } from "@tabler/icons-react";
import { Link } from "react-router-dom"; // Ensure you are using React Router for navigation
import { AccessPoint, Barcode, BrandProducthunt, Cards, DevicesPc, Moneybag, Printer, Scale } from "tabler-icons-react";

gsap.registerPlugin(ScrollTrigger);

const categories = [
  {
    title: "Portable Data POS",
    icon: <IconDeviceLaptop size={32} />,
    link: "/products/portable-pos",
  },
  {
    title: "POS System",
    icon: <IconBox size={32} />,
    link: "/products/pos-system",
  },
  {
    title: "POS Printer",
    icon: <IconPrinter size={32} />,
    link: "/products/pos-printer",
  },
  {
    title: "Barcode Scanner",
    icon: <IconBox size={32} />,
    link: "/products/barcode-scanner",
  },
  {
    title: "Weighing Scale",
    icon: <IconPrinter size={32} />,
    link: "/products/weighing-scale",
  },
  {
    name: "POS System",
    link: "/products/pos-system",
    icon: <DevicesPc size={32} className="mr-2" />,
  },
  {
    name: "Portable Data POS",
    link: "/products/portable-pos",
    icon: <DevicesPc size={32} className="mr-2" />,
  },
  {
    name: "POS Printer",
    link: "/products/pos-printer",
    icon: <Printer size={32} className="mr-2" />,
  },
  {
    name: "Barcode/Label Printers",
    link: "/products/barcode-printers",
    icon: <Barcode size={32} className="mr-2" />,
  },
  {
    name: "Barcode Scanner",
    link: "/products/barcode-scanner",
    icon: <Barcode size={32} className="mr-2" />,
  },
  {
    name: "Weighing Scale",
    link: "/products/weighing-scale",
    icon: <Scale size={32} className="mr-2" />,
  },
  {
    name: "ID Card Printer",
    link: "/products/id-card-printer",
    icon: <Cards size={32} className="mr-2" />,
  },
  {
    name: "Money Counters",
    link: "/products/money-counters",
    icon: <Moneybag size={32} className="mr-2" />,
  },
  {
    name: "Peripherals",
    link: "/products/peripherals",
    icon: <BrandProducthunt size={32} className="mr-2" />,
  },
  {
    name: "Time Attendance & Door Access",
    link: "/products/time-attendance",
    icon: <AccessPoint size={32} className="mr-2" />,
  },
  {
    name: "CCTV Cameras",
    link: "/products/cctv-cameras",
    icon: <DevicesPc size={32} className="mr-2" />,
  },
];

const CategoriesSection: React.FC = () => {
  const categoryRefs = useRef<HTMLDivElement[]>([]);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    categoryRefs.current = categoryRefs.current.slice(0, categories.length);

    // GSAP animation
    gsap.fromTo(
      categoryRefs.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 0.6,
        stagger: 0.2,
        ease: "power2.out",
        scrollTrigger: {
          trigger: categoryRefs.current[0]?.parentElement, // Trigger animation when parent is in view
          start: "left 70%", // Start when 80% of the viewport is reached
          end: "right 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.scrollY <= 0;
      setIsTop(top);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`md:pb-4 pb-0 bg-gray-50 text-center sticky top-0 ${
        isTop ?  "z-40" : "z-50"
      }`}
    >
      <div className="flex justify-start gap-4 overflow-x-scroll px-4 py-2 scrollbar-hide">
        {categories.map((category, index) => (
    <div
    key={index}
    ref={(el) => (categoryRefs.current[index] = el!)} // Attach ref
    className="flex flex-col items-center justify-center min-w-[120px] bg-gray-100 px-2 shadow-sm rounded-md w-auto cursor-pointer hover:bg-[#b33140] group transition-colors duration-300"
  >
    {category.link ? (
      <Link
        to={category.link}
        className="text-center place-items-center flex md:flex-col flex-row"
      >
        <div className="mb-0 md:mb-2 text-gray-700 group-hover:text-white transition-colors duration-300">
          {category.icon}
        </div>
        <p className="md:text-sm text-[9px] font-medium text-gray-700 group-hover:text-white transition-colors duration-300">
          {category.title || category.name}
        </p>
      </Link>
    ) : (
      <>
        <div className="mb-2 text-gray-700 group-hover:text-white transition-colors duration-300">
          {category.icon}
        </div>
        <p className="text-center text-sm font-medium text-gray-700 group-hover:text-white transition-colors duration-300">
          {category.title || category.name}
        </p>
      </>
    )}
  </div>
  
        ))}
      </div>
    </div>
  );
};

export default CategoriesSection;
