import React, { useEffect, useRef } from "react";
import { IconEye, IconUsers, IconTarget } from "@tabler/icons-react"; // Import Tabler Icons
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

const sections = [
    {
        title: "Vision",
        description: "To provide a quality and efficient modern technology to the retail business valuing the customer's highest satisfaction.",
        icon: <IconEye className="w-8 h-8 text-[#E83D52] absolute left-[-10px]" />,
    },
    {
        title: "About Us",
        description: "We are offering an all-in-one package of POS system including hardware, solution, maintenance and repair service by establishing our own R&D Center.",
        icon: <IconUsers className="w-8 h-8 text-[#E83D52] absolute left-[-10px]" />,
    },
    {
        title: "Mission",
        description: "We are concentrating all of our capacities on developing customer-oriented tailored solutions and offering fast and accurate maintenance and repair service.",
        icon: <IconTarget className="w-8 h-8 text-[#E83D52] absolute left-[-10px]" />,
    },
];

const VisionMission = () => {
    // Explicitly type the ref to hold HTMLDivElement or null
    const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const additionalRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        // Apply animations to each section
        sectionRefs.current.forEach((el, index) => {
            if (el) {
                gsap.fromTo(
                    el,
                    { opacity: 0, y: 50 },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 1,
                        ease: "power3.out",
                        scrollTrigger: {
                            trigger: el,
                            start: "top 80%",
                            end: "top 50%",
                            scrub: false,
                            toggleActions: "play none none reverse",
                        },
                    }
                );
            }
        });

        // Apply typing effect to additional content
        additionalRefs.current.forEach((el, index) => {
            if (el) {
                gsap.to(el, {
                    opacity: 1,
                    duration: 2, // Adjust the duration as needed
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: el,
                        start: "top 80%",
                        end: "top 50%",
                        scrub: false,
                        toggleActions: "play none none reverse",
                    },
                    onComplete: () => {
                        el.innerHTML = el.innerText.split("").map((char, i) => `<span style="display: inline-block; opacity: 0;">${char}</span>`).join("");
                        gsap.to(el.querySelectorAll("span"), {
                            opacity: 1,
                            stagger: 0.05,
                            duration: 0.2,
                        });
                    },
                });
            }
        });
    }, []);

    return (
        <div className="py-10 w-full border-[#e83d5124] border-y flex justify-center">
            <div className="max-w-3xl flex md:flex-col flex-row m-3 md:m-1 gap-3">
                <div className="w-full mx-auto flex justify-between  md:flex-row flex-col   ">

                    {sections.map((section, index) => (
                        <div
                            
                            key={index}
                            className="text-center bg-white p-6 rounded-[12px] border w-auto md:w-56 h-auto md:h-32 flex flex-col justify-between items-center border-[#e83d5198] hover:bg-[#e83d513b] hover:border-none cursor-pointer"
                        >
                            <div
                            ref={(el) => (sectionRefs.current[index] = el)}
                            >
                                <div
                                    className="flex items-center justify-center w-8 h-8 bg-[#e83d5154] rounded-full relative"
                                    style={{
                                        boxShadow: "1px 2px 4px 0 rgba(0, 0, 0, 0.42), 0 16px 22px 0 rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    {section.icon}
                                </div>
                            </div>
                            <h2
                            ref={(el) => (sectionRefs.current[index] = el)}
                            className="text-xl font-bold text-gray-800 mb-2">{section.title}</h2>
                        </div>
                    ))}
                </div>

                <div className="bg-[#e83d512c] w-full flex flex-col md:flex-row p-2 md:p-6 rounded-[12px] text-sm border  h-auto  text-center justify-center mt-0 md:mt-2 gap-7 place-self-center">
                    <div

                        className="w-auto md:w-56"
                    >
                        To provide a quality and efficient modern technology to the retail business valuing the customer’s highest satisfaction.
                    </div>
                    <div
                        className="w-auto md:w-56"
                    >
                        We provide an all-in-one POS package with hardware, solutions, maintenance, and repair through our dedicated R&D Center.
                    </div>
                    <div
                        className="w-auto md:w-56"
                    >
                        We focus on delivering tailored customer solutions and providing fast, accurate maintenance and repair services.
                    </div>
                </div>
            </div>

        </div>
    );
};

export default VisionMission;
