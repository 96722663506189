import React from 'react'
import Navbar from './components/Navbar'
import Carousalfirst from './components/Carousalfirst'
import VisionMission from './components/Vision'
import CategoriesSection from './components/Topcategories'
import AboutUsSection from './components/AboutUs'
import ProductsSection from './components/MainProducts'
import ClientsSection from './components/Ourclients'
import ContactUs from './components/ContactUs'
import ContactForm from './components/Formsection'
import Footer from './components/Footer'
import Button from './components/Button'

export default function Homepage() {
    return (
        <div>
          
            {/* <Navbarsm/> */}
            <Carousalfirst />
            <VisionMission />
            <div className='p-5 pb-0 bg-gray-50'>
                <Button text="Top Categories" />
            </div>
            <CategoriesSection />
            <AboutUsSection />
            <ProductsSection />
            <ClientsSection />
            <ContactUs />
            <ContactForm />
            <Footer />
        </div>
    )
}
