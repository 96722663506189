import React from 'react';
import { Link } from 'react-router-dom';
import {
    IconBrandFacebook,
    IconBrandInstagram,
    IconBrandLinkedin,
    IconBrandPinterest,
    IconBrandTwitter,
    IconBrandYoutube,
} from '@tabler/icons-react';

const Footer: React.FC = () => {
    return (
        <footer className="w-full bg-gray-200 py-8">
            <div className="container mx-auto flex flex-wrap justify-between items-start pl-8">
                {/* Logo and Description */}
                <div className="w-full lg:w-[50%] mb-6 lg:mb-0 mr-6">
                    <img src="./assets/trioslogo.png" alt="Trios Logo" className="mb-4 max-w-10" />
                    <h2 className="text-3xl font-bold ">Trios</h2>
                    <p className="text-gray-600 text-sm">
                        Subscribe to our newsletter and unlock a world of exclusive benefits. Be the first to
                        know about our latest products, special promotions, and exciting updates. Join our
                        community of like-minded individuals who share a passion for [your niche/industry].
                    </p>
                    {/* Social Media Links */}
                    <div className="flex space-x-4 mt-4">
                        <Link to="#" className="text-gray-600 hover:text-gray-800">
                            <IconBrandFacebook size={24} />
                        </Link>
                        <Link to="#" className="text-gray-600 hover:text-gray-800">
                            <IconBrandInstagram size={24} />
                        </Link>
                        <Link to="#" className="text-gray-600 hover:text-gray-800">
                            <IconBrandLinkedin size={24} />
                        </Link>
                        <Link to="#" className="text-gray-600 hover:text-gray-800">
                            <IconBrandPinterest size={24} />
                        </Link>
                    </div>
                </div>

                {/* Product Links */}
                <div className="w-full lg:w-[15%] mb-6 lg:mb-0">
                    <h3 className="text-gray-800 font-bold mb-4">Product</h3>
                    <ul className="space-y-2">
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">
                                Employee Database
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Payroll</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Absences</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Time Tracking</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Shift Planner</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Recruiting</Link>
                        </li>
                    </ul>
                </div>

                {/* Information Links */}
                <div className="w-full lg:w-[15%] mb-6 lg:mb-0">
                    <h3 className="text-gray-800 font-bold mb-4">Information</h3>
                    <ul className="space-y-2">
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">FAQ</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Blog</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Support</Link>
                        </li>
                    </ul>
                </div>

                {/* Company Links */}
                <div className="w-full lg:w-[15%]">
                    <h3 className="text-gray-800 font-bold mb-4">Company</h3>
                    <ul className="space-y-2">
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">About Us</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Careers</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="#" className="text-gray-600 hover:text-gray-800">Lift Media</Link>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="border-t border-gray-300 mt-8 pt-4 text-center text-gray-600 flex items-center justify-between mx-3 flex flex-col md:flex-row">
                 <p>&copy; 2024 All Rights Reserved</p>
                <div className='flex items-end gap-1 md:gap-5 flex flex-col md:flex-row'>
                    <div className="flex justify-center space-x-4 mt-2">
                        <Link to="#" className="text-gray-600 hover:text-gray-800">Terms</Link>
                        <Link to="#" className="text-gray-600 hover:text-gray-800">Privacy</Link>
                        <Link to="#" className="text-gray-600 hover:text-gray-800">Cookies</Link>
                    </div>
                    <div className="flex justify-center items-center md:items-baseline space-x-4 mt-4">
                        <Link to="#" className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]">
                            <IconBrandFacebook size={20} />
                        </Link>
                        <Link to="#" className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]">
                            <IconBrandTwitter size={20} />
                        </Link>
                        <Link to="#" className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]">
                            <IconBrandInstagram size={20} />
                        </Link>
                        <Link to="#" className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]">
                            <IconBrandYoutube size={20} />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
