import React from "react";
import { IconMapPin, IconPhone, IconMail } from "@tabler/icons-react"; // Using Tabler Icons

const ContactUs = () => {
  const contactDetails = [
    {
      title: "Address",
      icon: <IconMapPin size={40} className="text-[#e83d51a2]" />,
      details: (
        <>
          Office No. 204, Al Mansour Building, Damascus Street, Al Qusais, Dubai, UAE P.O Box:119360
        </>
      ),
    },
    {
      title: "Phone",
      icon: <IconPhone size={40} className="text-[#e83d51a2]" />,
      details: (
        <>
          <div>04 336 7323 285</div>
          <div>04 336 7323 934</div>
        </>
      ),
    },
    {
      title: "Email",
      icon: <IconMail size={40} className="text-[#e83d51a2]" />,
      details: (
        <>
          <div>info@triosme.com</div>
          <div>contact@triosme.com</div>
        </>
      ),
    },
  ];

  return (
    <div className="py-10 bg-[#e83d510e] border border-[#e83d518b]" id="#contact" >
      <h2 className="text-3xl font-bold text-center">Contact Us</h2>
      <p className="text-center opacity-65  mb-8">Get In Touch</p>
      <div className="max-w-5xl mx-auto grid gap-6 grid-cols-1 md:grid-cols-3">
        {contactDetails.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center p-6  rounded-lg"
          >
            {/* <div className="mb-4">{item.icon}</div> */}
            <div
              className="flex items-center justify-center w-8 h-8 bg-[#e83d5154] rounded-full relative"
              style={{
                boxShadow: "1px 2px 4px 0 rgba(0, 0, 0, 0.42), 0 16px 22px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="mb-4 absolute left-[-20px] bottom-[-20px]">{item.icon}</div>
            </div>
            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
            <div className="text-gray-600">{item.details}</div>
          </div>
        ))}
      </div>  
    </div>
  );
};

export default ContactUs;
