import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import {
  Search,
  ShoppingCart,
  Menu2,
  X,
  Printer,
  Barcode,
  DevicesPc,
  Scale,
  AccessPoint,
  Moneybag,
  Cards,
  BrandProducthunt,
  ChevronLeft,
  ChevronRight,
  ArrowRight,
  ArrowLeft,
} from "tabler-icons-react";
import axios from 'axios';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const linksRef = useRef<HTMLAnchorElement[]>([]);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://trios-backend-dev.prosevo.in/api/v1/customer/product-category?page=1&limit=15&m04_m04_parent_category_id=null');
        if (response.data.success) {
          setCategories(response.data.data.productCategories);
        
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (isMenuOpen && menuRef.current) {
      gsap.fromTo(
        menuRef.current,
        { y: "-100%", opacity: 0 },
        { y: "0%", opacity: 1, duration: 0.5, ease: "power3.out" }
      );
      gsap.fromTo(
        linksRef.current,
        { x: "-100%", opacity: 0 },
        {
          x: "0%",
          opacity: 1,
          duration: 0.5,
          ease: "power3.out",
          stagger: 0.1,
        }
      );
    } else if (!isMenuOpen && menuRef.current) {
      gsap.to(menuRef.current, {
        y: "-100%",
        opacity: 0,
        duration: 0.5,
        ease: "power3.in",
      });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scroll = (direction: string) => {
    const container = navbarRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -200 : 200;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };


  

  return (
    <>
      {/* Main Navbar */}
      <div
        className={`bg-black text-white flex justify-between items-center px-4 py-3 fixed top-0 left-0 w-full z-40 shadow-md transition-all duration-300`}
      >
        <Link to="/">
          <div className="text-xl font-bold">
            <img src="/assets/trioslogo.png" alt="Logo" className="h-8" />
          </div>
        </Link>

        <div className="hidden md:flex gap-6 text-sm flex-1 justify-center">
          <Link to="/" className="hover:text-gray-300">Home</Link>
          <Link to="#about" className="hover:text-gray-300">About</Link>
          <Link to="/Software-solution" className="hover:text-gray-300">Software Solutions</Link>
          <Link to="#contact" className="hover:text-gray-300">Contact</Link>
        </div>

        <div className="flex items-center gap-4">
          <Search className="text-white cursor-pointer" size={20} />
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden focus:outline-none"
          >
            {isMenuOpen ? (
              <X size={24} className="text-white" />
            ) : (
              <Menu2 size={24} className="text-white" />
            )}
          </button>
        </div>
      </div>

      {/* Categories Navbar */}
      <div
        ref={navbarRef}
        className={`bg-[#1c1c1c] text-white flex overflow-x-auto pt-2 fixed top-12 left-0 w-full z-30 shadow-md scrollbar-hide`}
      >
        <button
          onClick={() => {
            const container = document.querySelector('.categories-container');
            container?.scrollBy({ left: -100, behavior: 'smooth' });
          }}
          className="hidden md:flex items-center justify-center p-2 cursor-pointer hover:bg-black rounded transition-all"
        >
          <ArrowLeft size={20} />
        </button>

        <div className="categories-container overflow-x-auto scrollbar-hide hidden md:flex">
          {categories.map((category, index) => (
            <div className="flex items-center">
              <Link
                key={category.id}
                to={`/products/${category.m04_category_name.toLowerCase().replace(/ /g, '-')}`}
                className="flex items-center gap-2 px-4 py-2 my-1 whitespace-nowrap hover:bg-black rounded transition-all"
              >
                <img
                  className="rounded-full min-w-8"
                  src={`https://trios-backend-dev.prosevo.in/${category.m04_image}`}
                  alt={`${category.m04_category_name}`}
                />
                <span>{category.m04_category_name}</span>
              </Link>
              <span className="opacity-40">|</span>
            </div>
          ))}
        </div>

        <button
          onClick={() => {
            const container = document.querySelector('.categories-container');
            container?.scrollBy({ left: 100, behavior: 'smooth' });
          }}
          className="hidden md:flex items-center justify-center p-2 cursor-pointer hover:bg-black rounded transition-all"
        >
          <ArrowRight size={20} />
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        ref={menuRef}
        className={`fixed inset-0 bg-black bg-opacity-90 z-40 flex flex-col items-center justify-center text-white h-full space-y-6 ${isMenuOpen ? "block" : "hidden"}`}
      >
        <button
          onClick={() => setIsMenuOpen(false)}
          className="absolute top-4 right-4 text-white focus:outline-none"
        >
          <X size={32} />
        </button>
        {[
          { to: "/", label: "Home" },
          { to: "/about", label: "About Us" },
          { to: "/Software-solution", label: "Software Solution" },
          { to: "/contact", label: "Contact" },
        ].map((link, index) => (
          <Link
            key={index}
            to={link.to}
            ref={(el) => (linksRef.current[index] = el!)}
            onClick={() => setIsMenuOpen(false)}
            className="text-2xl hover:text-gray-300 transition-colors duration-300"
          >
            {link.label}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Navbar;
