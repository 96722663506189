import React from "react";
import Button from "./Button";

const ClientsSection = () => {
    const clients = [
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/230x115.png"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/B.jpg"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/D.jpg"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/A.jpg"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/01/c1.png"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/01/c2.png"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2023/12/c6.png"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/C.jpg"
        },
        {
            logoUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/230x115.png"
        },
    ];

    return (
        <div className=" overflow-hidden mt-0 m-7">
            <Button text="Our Clients" />
            <div className="marquee">
                <div className="marquee-content">
                    {clients.map((client, index) => (
                        <div key={index} className="p-4 flex-shrink-0">
                            <img
                                src={client.logoUrl}
                                alt={`Client ${index + 1} logo`}
                                className="w-24 h-24 object-contain mx-auto"
                            />
                        </div>
                    ))}
                    {/* Duplicate content for seamless looping */}
                    {clients.map((client, index) => (
                        <div key={`duplicate-${index}`} className="p-4 flex-shrink-0">
                            <img
                                src={client.logoUrl}
                                alt={`Client Duplicate ${index + 1} logo`}
                                className="w-24 h-24 object-contain mx-auto"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClientsSection;
