import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import Button from './Button';
import { BrandFacebook, BrandInstagram, BrandWhatsapp } from 'tabler-icons-react';

const CarousalFirst: React.FC = () => {
  const leftContentRef = useRef<HTMLDivElement>(null);
  const rightImageRef = useRef<HTMLDivElement>(null);
  const mainHeadingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    // Animation for the left content
    gsap.fromTo(
      leftContentRef.current,
      { opacity: 0, x: -50 },
      { opacity: 1, x: 0, duration: 1, ease: 'power2.out' }
    );

    // Animation for the right image from bottom to top
    gsap.fromTo(
      rightImageRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out', delay: 0.3 }
    );

    // Scrolling animation for the main heading
    gsap.fromTo(
      mainHeadingRef.current,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1.5, ease: 'power2.out', scrollTrigger: {
          trigger: mainHeadingRef.current,
          start: 'top 80%',
          end: 'top 50%',
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div
      className="h-auto min-h-96 md:flex-row flex-col-reverse flex justify-center items-center p-3 mt-12 md:mt-24"
      style={{
        background: "radial-gradient(circle, #f4433600 -5%, #f443367a -3%, #f0b34d40 118%) center center no-repeat",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* Left side content */}
      <div
        ref={leftContentRef}
        className="p-4 text-black max-w-[500px]"
      >
        <Button text="triosme" />
        <h1 ref={mainHeadingRef} className="text-4xl mt-3 font-bold">
          Streamline Your Business with Our Point of Sale Solution.
        </h1>
        <p className="mt-2 text-gray-500">
          Our POS system streamlines transactions with inventory tracking and real-time analytics.
        </p>
        <div className="flex space-x-2 mt-2">
          <Link to="/facebook" className="bg-red-400 rounded-full p-1">
            <BrandFacebook className="text-white cursor-pointer" size={24} />
          </Link>
          <Link to="/instagram" className="bg-red-400 rounded-full p-1">
            <BrandInstagram className="text-white cursor-pointer" size={24} />
          </Link>
          <Link to="/whatsapp" className="bg-red-400 rounded-full p-1">
            <BrandWhatsapp className="text-white cursor-pointer" size={24} />
          </Link>
        </div>
      </div>

      {/* Right side image */}
      <div
        ref={rightImageRef}
        className="flex-shrink-0"
      >
        <img
          src="./assets/posimg.png"
          alt="Carousel Image"
          className="h-full max-w-[600px] w-full object-cover shadow-black drop-shadow-2xl"
        />
      </div>
    </div>
  );
};

export default CarousalFirst;
