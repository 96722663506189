import React from 'react';

interface ButtonProps {
  text: string;
}

const Button: React.FC<ButtonProps> = ({ text }) => {
  return (
    <button className="px-4 py-1 bg-[#e83d513d] text-[#E83D52] font-bold rounded hover:bg-opacity-80 round-[16px]">
      {text}
    </button>
  );
};

export default Button;
