import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

const ProductsSection = () => {
    const products = [
        {
            title: "POS SYSTEM",
            description: "Navigate sales easily with secure payments and customer management in one place.",
            imageUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/image00009.jpg",
            linkText: "View more"
        },
        {
            title: "PORTABLE DATA POS",
            description: "Streamline your operations with quick, high-quality printing for receipts and labels.",
            imageUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/mm.jpg",
            linkText: "More info"
        },
        {
            title: "POS PRINTER",
            description: "Enhance business operations with reliable and consistent print performance—order now!",
            imageUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/pp.jpg",
            linkText: "More info"
        },
        {
            title: "MOBILE PRINTER",
            description: "Print receipts and documents on the go with our mobile printer—seamless integration.",
            imageUrl: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://triosme.com/wp-content/uploads/2022/06/1.png",
            linkText: "More info"
        }
    ];

    return (
        <div className="py-16 bg-gray-50 m-4">
            <div className="px-4">
                <Button text="Products" />
                <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-2 mt-1">
                    Our Products
                </h2>
            </div>

            <div className="max-w-7xl mx-auto  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 p-4">

                {products.map((product, index) => (
                    <div key={index} className="p-4 bg-white hover:bg-[#e83d5115] hover:border-[#ff000000] border border-red-300 rounded-lg cursor-pointer">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">{product.title}</h3>
                        <p className="text-gray-700 mb-4">{product.description}</p>
                        <Link to="#" className="px-4 py-1 bg-[#e83d513d] text-[#E83D52] font-bold rounded hover:bg-opacity-80 round-[16px]">
                            {product.linkText}
                        </Link>
                        <div className="mb-4 mt-4">
                            <img src={product.imageUrl} alt={product.title} className="w-full aspect-square max-h-52  object-fill rounded-lg" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsSection;
