import React from 'react';
import Button from './Button';

const ContactForm: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-gray-100 p-8">
      <div className="lg:w-1/2 text-center lg:text-left">
        <Button text='Messege Us'/>
        <h1 className="text-4xl font-bold mb-4 mt-4">Let's Talk<br />Get In Touch</h1>
        <p className="text-gray-600 mb-6">
          Need help or have a question? Our team is here to make your experience seamless and enjoyable! Whether you're curious about our products, need assistance with an order, or just want to say hello, we'd love to hear from you.
        </p>
        <div className="rounded-md overflow-hidden shadow-md">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d461786.03932123195!2d55.387260000000005!3d25.28056!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d31217aef4d%3A0xb78b5ae307035660!2sTrios%20International%20corporate%20office!5e0!3m2!1sen!2sin!4v1733999340629!5m2!1sen!2sin"
            title="Map location"
            className="w-full h-64 border-0"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <form className="lg:w-1/2  rounded-md  p-6 mt-8 lg:mt-0 lg:ml-8 w-full max-w-lg">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-400 focus:outline-none"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-400 focus:outline-none"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 font-medium mb-2">
            Message
          </label>
          <textarea
            id="message"
            rows={4}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-400 focus:outline-none"
          ></textarea>
        </div>

        <button
          type="submit"
          className=" w-full bg-[#cd5260] text-white px-4 py-2 rounded-md  transition duration-300"
        >
          Message Us
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
