import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Homepage';
import {  AlertTriangle } from 'tabler-icons-react';
import SolutionPage from './SolutionPage';
import Navbar from './components/Navbar';

const NotFound: React.FC = () => <>
  <div className="flex items-center justify-center h-screen bg-gray-900 text-white">
    <div className="text-center flex flex-col items-center">
      <AlertTriangle size={54} className="mb-4 text-red-100" />
      <div className="font-bold text-2xl text-red-100">
        404 - Page Not Found
      </div>
    </div>
  </div>
</>
  ;

const App: React.FC = () => {
  return (
    <Router>
        <Navbar />
      <div>
        <Routes>
          {/* Homepage Route */}
          <Route path="/" element={<Homepage />} />
          <Route path='/Software-solution' element={<SolutionPage/>} />

          {/* Not Found Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
